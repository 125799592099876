import { ActionTypes } from "../constants/ActionTypes";

export const setHomePageData = (data) => {
  return {
    type: ActionTypes.SET_HOME_PAGE_DATA,
    payload: data,
  };
};

// export const selectedProduct = (product) => {
//   return {
//     type: ActionTypes.SELECTED_PRODUCT,
//     payload: product,
//   };
// };
// export const removeSelectedProduct = () => {
//   return {
//     type: ActionTypes.REMOVE_SELECTED_PRODUCT,
//   };
// };