import axios from "axios";

// API'S
export const GET = (url) => {
  return axios.get(url).catch((err) => {
    console.log("Err", err);
  });
};

export const DELETE = (url) => {
  return axios.delete(url).catch((err) => {
    console.log("Err", err);
  });
};

export const PUT = (url, requestContact) => {
  return axios.put(url).catch((err) => {
    console.log("Err", err);
  });
};


export const POST = (url, req) => {
  return axios.post(url, req).catch((err) => {
    console.log("Err", err);
  });
};

// LOCAL STORAGE
export const SET_LOCAL_STORAGE = (name, value) => {
  // var arr = [];
  // arr = JSON.parse(localStorage.getItem(name)) || [];
  // arr.push(value);
  localStorage.setItem(name, JSON.stringify(value));
};
export const GET_LOCAL_STORAGE = (name) => {
  const saved = localStorage.getItem(name);
  const initialValue = JSON.parse(saved);
  return initialValue || "";
};
export const REM_LOCAL_STORAGE = (name) => {
  localStorage.removeItem(name);
};
// COOKIES
export const SET_COOKIES = (cname, cvalue, exdays) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};

// Price formater
export const FORMATEPRICE = (price) => {
  return price.toLocaleString(1,2);
};
