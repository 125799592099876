import React, { lazy,Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AaronzSpinner from "./components/spinner/AaronzSpinner";

const AaronzHomePage = lazy(() => import('./pages/AaronzHomePage'));
const AaronzAboutPage = lazy(() => import('./pages/AaronzAboutPage'));
const AaronzCategoryPage = lazy(() => import('./pages/AaronzCategoryPage'));
const AaronzDetailPage = lazy(() => import('./pages/AaronzDetailPage'));
const AaronzOffplanDetailPage = lazy(() => import('./pages/AaronzOffplanDetailPage'));
const AaronzOffplanPageWithFilter = lazy(() => import('./pages/AaronzOffplanPageWithFilter'));
const AaronzSignatureDetailPage = lazy(() => import('./pages/AaronzSignatureDetailPage'));
const AaronzSignaturePage = lazy(() => import('./pages/AaronzSignaturePage'));
const AaronzSignatureRentalPage = lazy(() => import('./pages/AaronzSignatureRentalPage'));
const AaronzTeamDetailPage = lazy(() => import('./pages/AaronzTeamDetailPage'));
const AaronzTeamPage = lazy(() => import('./pages/AaronzTeamPage'));
const AaronzCareerPage = lazy(() => import('./pages/AaronzCareerPage'));
const NotFound = lazy(() => import('./components/NotFound'));
const AaronzContactPage = lazy(() => import('./pages/AaronzContactPage'));
const AaronzBlogsPage = lazy(() => import('./pages/AaronzBlogsPage'));
const AaronzBlogsDetPage = lazy(() => import('./pages/AaronzBlogDetailPage'));
const AaronzReportDetPage = lazy(() => import('./pages/AaronzReportDetPage'));

const AaronzSellWithUsPage = lazy(() => import('./pages/AaronzSellWithUsPage'));
const AronzAreasPage = lazy(() => import('./pages/AronzAreasPage'));
const AaronzFavoritismPage = lazy(() => import('./pages/AaronzFavoritismPage'));
const AaronzLifeStyleAreaPage = lazy(() => import('./pages/AaronzLifeStyleAreaPage'));
const OurHistoryPage = lazy(() => import('./pages/OurHistoryPage'));
const AaronzTermCondPage = lazy(() => import('./pages/AaronzTermCondPage'));
const ArronzDownloadPage = lazy(() => import('./pages/AaronzDownloadPage'));
const AaronzDigestPage = lazy(() => import('./pages/AaronzDigestPage'));
const AaronzNewsPage = lazy(() => import('./pages/AaronzNewsPage'));
const AaronzReport = lazy(() => import('./pages/AaronzReport'));
const AaronzRoiPage = lazy(() => import('./pages/AaronzRoiPage'));
const AaronzRoiPageNew = lazy(() => import('./pages/AaronzRoiPageNew'));
const AaronzReportPage = lazy(() => import('./pages/AaronzReportPage'));
const AaronzReportsPage = lazy(() => import('./pages/AaronzReports'));
const AaronzQuaterlyPage = lazy(() => import('./pages/AaronzQuaterlyPage'));

export default function  AaronzPublicRoutes() {
  return (
    <div>
      <Router>
        <Switch>
        <Suspense fallback={<AaronzSpinner/>}>
        {/* <Route exact path="/" component={AaronzHomePage} />
        <Route exact path="/:type/:cate/:loc" component={AaronzCategoryPage}/>
        <Route exact path="/properties/:id" component={AaronzDetailPage} />
        <Route exact path="/off-plan" component={AaronzOffplanPageWithFilter}/>
        <Route exact path="/off-plan-:propCat"component={AaronzOffplanPageWithFilter}/>
        <Route exact path="/off-plan-:propCat/:id" component={AaronzOffplanDetailPage}/>
        <Route exact path="/signature" component={AaronzSignaturePage} />
        <Route xact path="/signature/:id"component={AaronzSignatureDetailPage}/>
        <Route exact path="/signature-rental" component={AaronzSignatureRentalPage}/>
        <Route exact path="/about-us" component={AaronzAboutPage} />
        <Route exact path="/contact-us" component={AaronzContactPage} />
        <Route exact path="/newsletter" component={AaronzNewsPage} />
        <Route exact path="/super-stars" component={AaronzTeamPage} />
        <Route exact path="/careers" component={AaronzCareerPage} />
        <Route exact path="/digest" component={AaronzDigestPage} />
        <Route exact path="/download" component={ArronzDownloadPage} />
        <Route exact path="/super-stars/:id" component={AaronzTeamDetailPage}/>
        <Route exact path="/blogs" component={AaronzBlogsPage} />
        <Route exact path="/blogs/:id" component={AaronzBlogsDetPage} />
        <Route exact path="/sell-with-us" component={AaronzSellWithUsPage} />
        <Route exact path="/life-style-in-dubai" component={AronzAreasPage} />
        <Route exact path="/life-style-in-dubai/:areaDet" component={AaronzLifeStyleAreaPage} />
        <Route exact path="/life-style-in-dubai/:areaDet/:area" component={AaronzCategoryPage} />
        <Route exact path="/favoritism" component={AaronzFavoritismPage} />
        <Route exact path="/our-history" component={OurHistoryPage} />
        <Route exact path="/terms-conditions" component={AaronzTermCondPage} />
        <Route exact path="/weekly-report" component={AaronzReport} />
        <Route exact path="/weekly-report/:id" component={AaronzReportDetPage} /> */}
        <Route exact path="/" component={AaronzRoiPage} />
        <Route exact path="/basic" component={AaronzRoiPageNew} />
        {/* <Route exact path="/report-detail" component={AaronzReportPage} />
        <Route exact path="/weekly-newsletters" component={AaronzReportsPage} />
        <Route exact path="/quarterly-newsletters" component={AaronzQuaterlyPage} /> */}
        
       </Suspense>
       <Route component={NotFound} /> 
        </Switch>
      </Router>
    </div>
  );
}
