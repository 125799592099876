import { combineReducers } from "redux";
import { CatePageReducer } from "./CatePageReducer";
import { DetailPageReducer } from "./DetailPageReducer";
import { HomePageRucer } from "./HomePageRucer";
import { OffPlanPageRucer } from "./OffPlanPageReducer";
import { SignaturePageRucer } from "./SignaturePageReducer";
import { WeeklyDetailPageReducer } from "./WeelyDetailPageReducer";

const reducers = combineReducers({
  HomePage: HomePageRucer,
  CatePageData: CatePageReducer,
  DetailPage: DetailPageReducer,
  OffPlanPage: OffPlanPageRucer,
  WeeklyDetailPage: WeeklyDetailPageReducer,
  SignaturePage: SignaturePageRucer,
});
export default reducers;
