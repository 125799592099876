import { ActionTypes } from "../constants/ActionTypes";
const intialState = {
    data : []
};


export const CatePageReducer = (state = intialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_CATE_PAGE_DATA:
      return { ...payload };
    case ActionTypes.LOAD_MORE_PRODUCT:
      let updatedCategoriesData = [...state.data , ...payload.data];
      return { ...state, ...payload , data: updatedCategoriesData };
    default:
      return state;
  }
};

// export const selectedProductsReducer = (state = {}, { type, payload }) => {
//   console.log(type);
//   switch (type) {
//     case ActionTypes.SELECTED_PRODUCT:
//       return { ...state, ...payload };
//     case ActionTypes.REMOVE_SELECTED_PRODUCT:
//       return {};
//     default:
//       return state;
//   }
// };
