import { ActionTypes } from "../constants/ActionTypes";
const intialState = {
  OffPlanPageData: {},
};

export const OffPlanPageRucer = (state = intialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_OFF_PLAN_DATA:
      return { ...payload };
    // case ActionTypes.LOAD_MORE_PRODUCT:
    //   let updatedPropertiesData = [...state.OffPlanPageData, ...payload];
    //   return { ...state, ...payload, OffPlanPageData: updatedPropertiesData };
    default:
      return state;
  }
};

// export const selectedProductsReducer = (state = {}, { type, payload }) => {
//   console.log(type);
//   switch (type) {
//     case ActionTypes.SELECTED_PRODUCT:
//       return { ...state, ...payload };
//     case ActionTypes.REMOVE_SELECTED_PRODUCT:
//       return {};
//     default:
//       return state;
//   }
// };
