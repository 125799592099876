import { ActionTypes } from "../constants/ActionTypes";
const intialState = {
  WeeklyDetailPageData: {},
};

export const WeeklyDetailPageReducer = (state = intialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_WEEKLY_DETAIL_PAGE_DATA:
      return { ...payload };
    // case ActionTypes.LOAD_MORE_PRODUCT:
    //   return { ...state, ...payload };
    default:
      return state;
  }
};