import React, { useEffect, useState } from "react";
import AaronzPublicRoutes from "./AaronzPublicRoutes";
import "./assets/scss/app.scss";
import { LIVE } from "./redux/apis/Constants";
import { GET } from "./redux/apis/Utils";
import { useDispatch } from "react-redux";
import { setHomePageData } from "./redux/actions/HomePageActions";
import AaronzSpinner from "./components/spinner/AaronzSpinner";



function App() {
  const [loader, setLoader] = useState(false);
  // variables
  const dispatch = useDispatch();

  // https://aaronz.techbay.co/api/get-home-data functional
  const fetchHomeData = async () => {
    setLoader(true);
    const url = LIVE + "/api/get-home-data";
    
    const response = await GET(url);
    // console.log("response", response);
    try {
      if (response.data.code === 200) {
        dispatch(setHomePageData(response.data.response));
        // console.log("home-data");
        setLoader(false);
      }
    } catch (error) {
      // console.log("Home data not found | Network issue");
      // console.log(error);
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchHomeData();
  }, []);

  return (
    <div>
      <AaronzPublicRoutes />
      {loader && <AaronzSpinner />}
    </div>
  );
}

export default App;
